class LocalStorage {
    static setItem(key: string, value: any): void {
      let data:any = localStorage.getItem(key);
      if (data) {
        data = JSON.parse(data);
        Object.assign(data, value);
        localStorage.setItem(key, JSON.stringify(data));
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    }
    static getItem(key: string): any {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    }
    static clearItem(key:string): any {
      localStorage.removeItem(key)
    }
  }
  
  export default LocalStorage;
  