
import { defineComponent,ref,watch,createVNode } from 'vue'
import { Card,Tag,Space,Input,message,Drawer,Modal,Dropdown,Menu } from 'ant-design-vue'
import { ColumnHeightOutlined,ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { promptArrayFormat,formatPromptData,debounce,throttle } from '@/utils/utils'
import Draggable from 'vuedraggable'
import PromptTag from './promptTag.vue'
import PromptPresets from './promptPresets.vue'
import { PromptType,PromptArray } from './typeInterface'
import useClipboard from "vue-clipboard3";
export default defineComponent({
  components: { 
    Card,
    Tag,
    Space,
    Input,
    Textarea:Input.TextArea,
    PromptTag,
    Draggable,
    Drawer,
    PromptPresets,
    Modal,
    Dropdown,
    Menu,
    MenuItem:Menu.Item,
    ColumnHeightOutlined 
  },
	name:'PromptGroup',
  props:{
    title:{
      type:String,
      default:''
    },
    doc:{
      type:String,
      default:''
    },
    prompt:{
      type:Array,
      default:[]
    }
  },
  setup(props:any,{ emit }:any){
    const promptData = ref<Array<PromptType>>([])
    const tmpPromptData = ref<Array<PromptArray>>([])
    const promptFilterStr = ref<string>('')
    const drawerVisible = ref<boolean>(false)
    const editStatus = ref<boolean>(true)
    const direction = ref<string>('right')
    const { toClipboard } = useClipboard();
    // 粘贴进来需要格式化的字符串
    const promptStr = ref('')
    // 类型名称输入框
    const typeName = ref('')
    const title = props.title
    const doc = props.doc
    promptData.value = props.prompt
    // 格式化字符串
    watch(()=>promptStr.value,(v:string)=>{
      promptArrayFormat(v).then((res:any)=>{
        tmpPromptData.value = res
      })
    })
    // 格式化字符串
    watch(()=>promptData.value,(v:any)=>{
      promptFilterStr.value = formatPromptData(v)
      emit('update:prompt', v);
    },{deep:true})
    watch(()=>props.prompt,(v:any)=>{
      if (!Array.isArray(v)) {
        message.error('关键词有误，请修改后重新保存')
        return;
      }
      promptData.value = v
    },{deep:true})
    // 增加类型
    const addType = ()=>{
      // 判断不为空
      // 判断字符串是否为空或者只包含空格
      if (!typeName.value || /^\s+$/.test(typeName.value)) {
        message.error('碳基生物的记性就是不好，需要填写类型名称')
        return false;
      }
      // 判断字符串是否包含危害数据库安全的特殊字符
      const regex = /[';|{}$&%@\\\[\]\(\)<>\*\+\?~^\/\\]/g;
      if (regex.test(typeName.value)) {
        message.error('碳基生物，你输入的内容很危险哦！')
        return false;
      }
      const data = {
        typeName : typeName.value,
        promptData : []
      }
      promptData.value.push(data)
      typeName.value = ''
    }
    // 工具栏操作
    const tool = {
      tmtPrompt:()=>{
        // 翻译工具栏内所有没有翻译的词语
        // const newData = tmpPromptData.value.map((item:any, index:number) => ({ [index as number]: item.prompt }))
        //            .filter(item => item[Object.keys(item)[0]] !== '');

        // console.log(newData);
        // console.log(tmpPromptData.value)
        
      },
      copyPrompt:()=>{
        // 复制到剪切板
        const str = promptFilterStr.value.toString()
        if(!str){
          message.error('没有内容可复制')
          return
        }
        toClipboard(str)
        message.success('已成功复制到剪切板')
      },
      clearPrompt:()=>{
        Modal.confirm({
          content: '是否需要清空当前的预设prompt？如果误清空后请勿保存预设！',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            // 清空关键词
            promptFilterStr.value = ''
            // 增加确定按钮，确定是否清空操作，清空操作会清空当前提示词，且不可恢复
            promptData.value = []
          },
          cancelText: '取消',
          okText:'确认清空',
          onCancel() {
            Modal.destroyAll();
          },
        });
      }
    }
    // 打开面板区
    const drawerOpen = ()=>{
      if(doc=='affirmative'){
        direction.value = 'right'
      }else{
        direction.value = 'left'
      }
      drawerVisible.value = true
    }
    // 关闭面板
    const drawerClose = ()=>{
      drawerVisible.value = false
    }
    // 删除类型
    const onDelete = (data:any)=>{
      Modal.confirm({
        content: '是否确认要删除当前类型吗？删除当前类型，当前类型下的咒语会被分配到未定义分类下，请重新拖拽分类！',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          // 分配至未定义分类
          tmpPromptData.value = data.promptData
          promptData.value = promptData.value.filter(obj => obj !== data)
        },
        cancelText: '取消',
        okText:'确认删除',
        onCancel() {
          Modal.destroyAll();
        },
      });
    }
    // 编辑文字
    const editText = (data:any)=>{
      editStatus.value = false
    }
    // 完成编辑
    const handleBlur = ()=>{
      editStatus.value = true
    }
    return{
			promptData,
      promptStr,
      promptFilterStr,
      typeName,
      tmpPromptData,
      drawerVisible,
      editStatus,
      direction,
      title,
      tool,
      doc,
      addType,
      drawerOpen,
      drawerClose,
      onDelete,
      editText,
      handleBlur
		}
	}
})
