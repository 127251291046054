import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-068938b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spin = _resolveComponent("Spin")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loading", {'nonebg':!_ctx.modal}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Spin, {
        size: "large",
        spinning: _ctx.visible,
        indicator: _ctx.indicator
      }, null, 8, ["spinning", "indicator"])
    ])
  ], 2)), [
    [_vShow, _ctx.visible]
  ])
}