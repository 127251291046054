import http from '@/utils/http'
enum Api {
  getCatePrompt = '/api/prompt/getCatePrompt',
  getPrompt = '/api/prompt/getPrompt',
  savePrompt = '/api/presets/presetsAdd',
  updatePrompt = '/api/presets/presetsEdit',
  getUserPromptList = '/api/presets/presetsList',
  delPrompt = '/api/presets/presetsDelete'
}
const getCatePrompt = (data?: Record<string, any>)=>{
  return http.post(Api.getCatePrompt,data)
}
const getPrompt = (data: Record<string, any>)=>{
  return http.get(Api.getPrompt,data)
}
// 查询当前保存的内容
const getUserPromptList = (data?: Record<string, any>)=>{
  return http.get(Api.getUserPromptList,data)
}
// 保存当前预设
const savePrompt = (data?: Record<string, any>)=>{
  return http.post(Api.savePrompt,data)
}
// 更新当前预设
const updatePrompt = (data?: Record<string, any>)=>{
  return http.post(Api.updatePrompt,data)
}
// 删除预设
const delPromot = (data?: Record<string, any>)=>{
  return http.post(Api.delPrompt,data)
}
export { getCatePrompt,getPrompt,getUserPromptList,savePrompt,updatePrompt,delPromot }