
import { defineComponent,ref,watch,h } from 'vue'
import { Spin } from 'ant-design-vue'
import { LoadingOutlined } from '@ant-design/icons-vue';
export default defineComponent({
  components: { Spin },
	name:'Loading',
	props:{
		visible:{
			type:Boolean,
			default:false
		},
		modal:{
			type:Boolean,
			default:true
		},
	},
  setup(props){
		const modal = ref<Boolean>(props.modal)
		const visible = ref<Boolean>(props.visible)
		const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '24px',
      },
      spin: true,
    });
		watch(() => props.visible, (v) => {
			visible.value = props.visible
		})
		return{
			visible,
			modal,
			indicator
		}
	}
})
