import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPane = _resolveComponent("TabPane")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Draggable = _resolveComponent("Draggable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Tabs, { onChange: _ctx.change }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeList, (v) => {
          return (_openBlock(), _createBlock(_component_TabPane, {
            key: v.id,
            tab: v.name
          }, null, 8, ["tab"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["onChange"]),
    _createVNode(_component_Draggable, {
      list: _ctx.promptData,
      onStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dragging = true)),
      onEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dragging = false)),
      group: { name: 'promotGroup', pull: 'clone', put: false },
      clone: _ctx.cloneDog,
      "force-fallback": true,
      "item-key": "id",
      "ghost-class": "ghost",
      "chosen-class": "chosenClass"
    }, {
      item: _withCtx(({ element }) => [
        _createVNode(_component_Tag, { tag: element }, null, 8, ["tag"])
      ]),
      _: 1
    }, 8, ["list", "clone"])
  ]))
}