
import { ref, watch,toRefs, nextTick } from 'vue';
import { calculateWeight } from '@/utils/utils'
import { Space, message } from 'ant-design-vue'
import { PromptArray } from './typeInterface'
import Tag from './Tag.vue';
import Draggable from 'vuedraggable'
export default {
  components: { Space,Draggable,Tag },
  name: 'PromptTag',
  props:{
    tag:{
      type:Array,
      default:[
        {
          text : String,
          prompt : String,
          disabled: Boolean || null || undefined,
        }
      ]
    },
  },
  setup(props:any,{ emit }:any) {
    const promptList = ref<Array<PromptArray>>([])
    const dragging = ref<Boolean>(false)
    nextTick();
    if(props.tag.length>0){
      promptList.value = props?.tag
    }
    watch(()=>props.tag,(v)=>{
      promptList.value = v
    })
    // 监听数组是否改变，如果改变要双向绑定父组件
    watch(()=>promptList.value,(v)=>{
      emit('update:tag', v);
    },{deep:true})
    // 权重计算
    const strWeight = (str:string)=>{
      return calculateWeight(str);
    }
    // 删除咒语
    const onDelete =(e:any)=>{
      // Tag.value.text = '我试试'
      promptList.value = promptList.value.filter(obj => obj !== e)
    }
    return {
      promptList,
      dragging,
      strWeight,
      onDelete,
    };
  },
  mounted() {
    
  },
};
