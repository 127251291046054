import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/index/Home.vue'
import Space from '@/views/mySpace/index.vue'
import ArticleList from '@/views/article/list.vue'
import ArticleDetail from '@/views/article/Detail.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mySpace',
    name: 'myspace',
    component: Space
  },
  {
    path: '/articleList',
    name: 'articleList',
    component: ArticleList
  },
  {
    path: '/detail',
    name: 'Detail',
    component: ArticleDetail
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
