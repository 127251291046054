import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Draggable = _resolveComponent("Draggable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Draggable, {
      list: $setup.promptList,
      onStart: _cache[0] || (_cache[0] = ($event: any) => ($setup.dragging = true)),
      onEnd: _cache[1] || (_cache[1] = ($event: any) => ($setup.dragging = false)),
      "force-fallback": true,
      "item-key": "id",
      "ghost-class": "ghost",
      "chosen-class": "chosenClass",
      group: "promotGroup"
    }, {
      item: _withCtx(({ element }) => [
        _createVNode(_component_Tag, {
          tag: element,
          onOnDel: $setup.onDelete
        }, null, 8, ["tag", "onOnDel"])
      ]),
      _: 1
    }, 8, ["list"])
  ]))
}