
import { defineComponent,ref,watch,h } from 'vue'
import { Modal,Form,Input,Button,Space,Row,Col,message } from 'ant-design-vue'
import { UserOutlined,LockOutlined } from '@ant-design/icons-vue'
import { userLogin,register } from '@/api/user/user'
import LocalStorage from '@/utils/localStorage';
import { toUrl } from '@/utils/utils'
export default defineComponent({
  components: { 
    Modal,
    Form,
    FormItem:Form.Item,
    Input,
    InputPassword:Input.Password,
    Button,
    Space,
    Row,
    Col,
    UserOutlined,
    LockOutlined
  },
	name:'Login',
	props:{
		visible:{
			type:Boolean,
			default:false
		},
	},
  setup(props){
		const visible = ref<Boolean>(props.visible)
		const title = ref<String>('碳基生物，欢迎来到自留地')
    const act = ref('login')
    const code = ref<number>()
    const invitation = ref()
    const formState = ref<any>({
      account:'',
      code:'',
      password:'',
      scene:"1",
      terminal:"4"
    })
    const formStateReg = ref<any>({
      account: '',
      channel: '4',
      password: "",
      password_confirm: "",
      invitationCode:'',
      verify:''
    })
    watch(() => props.visible, (v) => {
			visible.value = props.visible
		})
    const onFinish = ()=>{
      // 完成验证
      if(act.value == 'login'){
        userLogin(formState.value).then((res:any)=>{
          message.success('碳基生物，你已成功进入硅基领地')
          LocalStorage.setItem('userInfo',res)
          visible.value = false
          toUrl('myspace')
          clearn()
        })
      }
      if(act.value == 'register' ){
        register(formStateReg.value).then((res:any)=>{
          message.success('注册成功')
          clearn()
          act.value = 'login'
          // message.success('碳基生物，你已成功进入硅基领地')
          // LocalStorage.setItem('userInfo',res)
          // visible.value = false
          
        })
      }
    }
    const clearn = ()=>{
      formState.value = {
        account:'',
        code:'',
        password:'',
        scene:"1",
        terminal:"4"
      }
      formStateReg.value = {
        account: '',
        channel: '4',
        password: "",
        password_confirm: "",
        invitationCode:'',
        verify:''
      }
    }
    const onFinishFailed = (errorInfo: any)=>{
      // 验证错误
      console.log('Failed:', errorInfo);
    }
    // 用户注册
    const action = (action:string)=>{
      act.value = action
      console.log(act.value)
    }
    const getVerify = ()=>{
      const date = new Date()
      code.value = date.getTime()
    }
    getVerify()
    // 检测是否需要邀请码注册
    const checkInvitation = ()=>{
      try {
        invitation.value = LocalStorage.getItem('config').login.invitation;
      } catch (error) {
        console.log(error)
      }    
    }
    checkInvitation()
		return{
			visible,
      title,
      formState,
      act,
      code,
      invitation,
      onFinish,
      onFinishFailed,
      action,
      formStateReg,
      getVerify
		}
	}
})
