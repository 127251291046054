
import { defineComponent,ref,watch, } from 'vue'
import { useLogin } from '@/components/login/userLogin.ts'
import LocalStorage from '@/utils/localStorage';
import { Dropdown,Menu,Avatar } from 'ant-design-vue'
import { toUrl,getUrl } from '@/utils/utils'
import { articleCate } from '@/api/article/article'
import { useRoute } from "vue-router"
export default defineComponent({
  components: { 
    Dropdown,
    Menu,
    MenuItem:Menu.Item,
    Avatar
  },
	name:'Aheader',
	props:{
		webconfig:{
			type:Object,
			default:{
        pc_desc: null,
        pc_ico: "",
        pc_keywords: null,
        pc_logo: "",
        pc_title: "",
        shop_logo: "",
        shop_name: "",
      }
		},
	},
  setup(props){
    const route = useRoute()
    const config = ref(props.webconfig);
    const current = ref<string[]>(['home']);
    const nav = ref<Array<any>>([]);
    const userInfo = ref({
      avatar:'',
      mobile:'',
      nickname:'',
      token:'',
      sn:''
    })
    // 获取是否登录
    const getLoginInfo = ()=>{
      // 获取登录信息
      userInfo.value = LocalStorage.getItem('userInfo');
      console.log('获取了登录信息')
    }
    const Login = useLogin(getLoginInfo)
    const setTitle = (title:string)=>{
      document.title = title;
    }
    setTitle(config.value.pc_title)
		watch(() => props.webconfig, (v) => {
			config.value = props.webconfig
      setTitle(props.webconfig.pc_title)
		})
    // 显示当前时间
    const currentTime = ref('');
    const getTimenow = ():void=>{
      setInterval(() => {
        const now = new Date();
        currentTime.value = '硅基时间：'+`${now.getFullYear()}-${padZero(now.getMonth() + 1)}-${padZero(now.getDate())} ${padZero(now.getHours())}:${padZero(now.getMinutes())}:${padZero(now.getSeconds())}`;
      })
    }
    // 获取menu
    const getMenu = ()=>{
      articleCate().then((res:any)=>{
        nav.value = res
      })
    }
    //转换月份+1
    const padZero = (num:number)=>{
      return num.toString().padStart(2, '0');
    }
    // 开始显示时间
    getTimenow()
    getLoginInfo()
    // 显示注册登录弹窗
    const login = ()=>{
      Login.show()
    }
    // 退出登录
    const logOut = ()=>{
      Login.logOut()
      url('Home')
      clearn()
    }
    // 清空登录数据
    const clearn = ()=>{
      userInfo.value = {
        avatar:'',
        mobile:'',
        nickname:'',
        token:'',
        sn:''
      }
    }
    // 跳转链接
    const url = (url:string,v?:any)=>{
      // 判断是否有参数，有的话需要加上去
      if(v?.id){
        let parmas = {
          id:v.id,
          name:v.name
        }
        getUrl(url,parmas)
        return
      }
      toUrl(url)
    }
    // 获取当前的参数
    // if(route.query?.name){
    //   current.value = <any>route.query?.id
    //   console.log(current.value)
    // }
    getMenu()
		return{
      login,
      logOut,
      url,
      nav,
      current,
			config,
      currentTime,
      userInfo
		}
	}
})
