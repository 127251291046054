import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_PromptGroup = _resolveComponent("PromptGroup")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_UngroupOutlined = _resolveComponent("UngroupOutlined")!
  const _component_Input = _resolveComponent("Input")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_Upload = _resolveComponent("Upload")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_row, { gutter: [0, { xs: 8, sm: 16, md: 24, lg: 32 }] }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Alert, {
                      message: "输入类型后点击增加类型,可以增加提示词的分类。提示词自动识别后,右键提示词可以有更多功能操作。",
                      type: "warning",
                      closable: ""
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PromptGroup, {
                      title: "正面提示词（prompt）",
                      prompt: _ctx.prompt,
                      "onUpdate:prompt": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.prompt) = $event)),
                      doc: "affirmative"
                    }, null, 8, ["prompt"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PromptGroup, {
                      title: "反面提示词（Negative Prompt）",
                      prompt: _ctx.negativePrompt,
                      "onUpdate:prompt": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.negativePrompt) = $event)),
                      doc: "negative"
                    }, null, 8, ["prompt"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.$props.models!='guest')
          ? (_openBlock(), _createBlock(_component_a_col, {
              key: 0,
              span: 24
            }, {
              default: _withCtx(() => [
                (_ctx.promptData.id)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      type: "primary",
                      block: "",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible=true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("更新预设")
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_a_button, {
                      key: 1,
                      type: "primary",
                      block: "",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.visible=true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("保存预设")
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.$props.models!='guest')
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          visible: _ctx.visible,
          "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.visible) = $event)),
          title: "保存当前预设",
          footer: null
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Form, {
              model: _ctx.formState,
              name: "basic",
              autocomplete: "off",
              onFinish: _ctx.onFinish
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormItem, {
                  label: "",
                  name: "name",
                  rules: [{ required: true, message: '碳基生物，快，给它一个名字' }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Input, {
                      value: _ctx.formState.name,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.name) = $event)),
                      placeholder: "预设名称"
                    }, {
                      prefix: _withCtx(() => [
                        _createVNode(_component_UngroupOutlined)
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormItem, {
                  label: "预览图片",
                  name: "name"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Upload, {
                      value: _ctx.formState.image,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.image) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormItem, {
                  label: "",
                  name: "name"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Textarea, {
                      rows: 4,
                      "show-count": "",
                      value: _ctx.formState.remarks,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.remarks) = $event)),
                      placeholder: "有什么需要说明的吗？",
                      maxlength: 1000
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormItem, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      "html-type": "submit",
                      block: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("确认提交")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "onFinish"])
          ]),
          _: 1
        }, 8, ["visible"]))
      : _createCommentVNode("", true)
  ]))
}