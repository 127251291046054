
import { ref, watch, nextTick } from 'vue';
import { calculateWeight,filterTags } from '@/utils/utils'
import { Space,Dropdown,Menu,Input } from 'ant-design-vue'
import { PromptArray, PromptType } from './typeInterface'
import { translate } from '@/api/prompt/translate'
export default {
  components: { 
    Space,
    Dropdown,
    Input,
    Menu,
    MenuItem:Menu.Item
  },
  name: 'Tag',
  props:{
    tag:{
      type:Object,
      default:
      {
        text : String,
        prompt : String,
        disabled: Boolean || null || undefined,
      }
    },
  },
  setup(props:any,{ emit }:any) {
    // 咒语编辑状态
    const promptEdit = ref<boolean>(true)
    const textEdit = ref<boolean>(true)
    const Tag = ref<any>({
      text : '',
      prompt : '',
    })
    nextTick();
    Tag.value = props?.tag
    if(Tag.value.disabled==='1'){
      Tag.value.disabled = '1'
    }
    watch(()=>props.tag,(v)=>{
      Tag.value = v
    })
    // 权重计算
    const strWeight = (str:string)=>{
      return calculateWeight(str);
    }
    // 禁用
    const ondisabled = ()=>{
      Tag.value.disabled = ''
      emit('update:tag', Tag.value);
    }
    // 启用
    const onEnable = ()=>{
      Tag.value.disabled = '1'
      emit('update:tag', Tag.value);
    }
    // 删除咒语
    const onDelete =()=>{
      // Tag.value.text = '我试试'
      console.log('删除')
      emit('onDel', Tag.value);
    }
    // 失去焦点，让输入框变为不可编辑
    const handleBlur =()=>{
      promptEdit.value = true
    }
    // 编辑咒语
    const editP = ()=>{
      promptEdit.value = false
    }
    // 编辑中文
    const editT =()=>{
      textEdit.value = false
    }
    // 编辑完成编辑中文
    const textBlur = ()=>{
      textEdit.value = true
    }
    const onTmt = ()=>{
      const data = {
        form: filterTags(Tag.value.prompt)
      }
      console.log(filterTags(Tag.value.prompt),data)
      
      // return
      translate(data).then((res:any)=>{
        console.log(res)
        Tag.value.text = res.trans_result[0].dst
      })
    }
    
    return {
      Tag,
      promptEdit,
      textEdit,
      editT,
      strWeight,
      handleBlur,
      textBlur,
      editP,
      ondisabled,
      onEnable,
      onDelete,
      onTmt,
    };
  },
  mounted() {
    // 调用函数开始打字机效果
  },
};
