import http from '@/utils/http'
enum Api {
    register = '/api/login/register',
    login = '/api/login/account'
}
// 注册
const register = (data: Record<string, any>)=>{
  return http.post(Api.register,data)
}
// 登录
const userLogin = (data:Record<string, any>)=>{
  return http.post(Api.login,data)
}
export { register,userLogin }