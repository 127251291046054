
import { defineComponent,ref,watch,toRaw } from 'vue'
import PromptGroup from './promptGroup.vue';
import Upload from '@/components/upload/upload.vue';
import { Form,Modal,Input, message,Alert } from 'ant-design-vue'
import { UngroupOutlined } from '@ant-design/icons-vue'
import { savePrompt,updatePrompt } from '@/api/prompt/prompt'
import { fileDomain,replaceFile } from '@/utils/utils'
export default defineComponent({
  components: { 
    PromptGroup,
    Upload,
    Form,
    FormItem:Form.Item,
    Input,
    Textarea:Input.TextArea,
    Modal,
    Alert,
    UngroupOutlined
  },
	name:'Prompt',
  props:{
    promptData:{
      type:Object,
      default:{
        negativePrompt:[],
        prompt:[]
      }
    },
    models:{
      type:String,
      default:''
    },
  },
  setup(props,{ emit }:any){
    const visible = ref<boolean>(false)
    const formState = ref<any>({
      name:'',
      image:[],
      remarks:'',
    })
    const prompt = ref<any>([])
    const negativePrompt = ref<any>([])
    prompt.value = props.promptData?.prompt.length >0?props.promptData?.prompt:[]
    negativePrompt.value = props.promptData?.negativePrompt >0?props.promptData?.negativePrompt:[]
    // 预设变动
    watch(()=>props.promptData,(v:any)=>{
      if(v?.id){
        formState.value.image = v.image && typeof v.image === 'string'? [{url:fileDomain()+v.image}] :[]
        formState.value.name = v.name;
        formState.value.remarks = v.remarks;
      }else{
        clearn()
      }
      prompt.value = v.prompt
      negativePrompt.value = v.negativePrompt
    },{deep:true})
    const onFinish = ()=>{
      // 完成验证
      let data:any = Object.assign({}, formState.value);
      data.prompt = prompt.value
      data.negativePrompt = negativePrompt.value
      data.image = formState.value?.image[0]?.uri?formState.value?.image[0]?.uri:replaceFile(formState.value?.image[0]?.url)
      if(props.promptData.id){
        data.id = props.promptData.id
        data.listId = props.promptData.listId
        updatePrompt(data).then(()=>{
          message.success('更新成功')
          visible.value = false
          // clearn()
          emit('onChange')
        })
      }else{
        // 判断是否有ID，如果有ID则是更新，没有ID是添加
        savePrompt(data).then((res)=>{
          message.success('增加成功')
          visible.value = false
          clearn()
          emit('onChange')
        })
      }
    }
    const clearn = ()=>{
      formState.value = {
        name:'',
        image:[],
        remarks:'',
      }
    }
    return{
			visible,
      formState,
      negativePrompt,
      prompt,
      onFinish,
		}
	}
})
