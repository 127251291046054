
import { defineComponent,ref,watch,h } from 'vue'
import { Spin } from 'ant-design-vue'
import { LoadingOutlined } from '@ant-design/icons-vue';
export default defineComponent({
  components: { Spin },
	name:'Afooter',
	props:{
		webCopyRight:{
			type:Array,
			default:[]
		},
	},
  setup(props){
    const config = ref(props.webCopyRight);
		watch(() => props.webCopyRight, (v) => {
			config.value = props.webCopyRight
		})
		return{
			config
		}
	}
})
