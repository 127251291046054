import LoadingComponent from '@/components/loading/loadingCompoent.vue'
import { createApp } from 'vue'

let loadingInstance: any = null

export function useLoading() {
  // 如果之前没有挂载过loading组件，就先挂载到全局
  if (!loadingInstance) {
    const app = createApp(LoadingComponent)
    loadingInstance = app.mount(document.createElement('div'))
    document.body.appendChild(loadingInstance.$el)
  }

  // 返回的方法
  return {
    show: (modal:Boolean=true) => {
      loadingInstance.visible = true
      loadingInstance.modal = modal
    },
    hide: () => {
      loadingInstance.visible = false
    }
  }
}
