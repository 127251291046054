
import http from '@/utils/http'
enum Api {
	cate = '/api/article/cate',
	articleDetail = '/api/pc/articleDetail',
	lists = '/api/article/lists'
}
// 获取分类
const articleCate = (data?: Record<string, any>)=>{
	return http.get(Api.cate,data)
}
// 获取文章详情
const articleDetail = (data?: Record<string, any>)=>{
	return http.get(Api.articleDetail,data)
}
// 文章列表
const articleLists = (data?: Record<string, any>)=>{
	return http.get(Api.lists,data)
}
export { articleCate,articleDetail,articleLists }