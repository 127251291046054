
import { ref } from 'vue';
export default {
	name: 'typewriterText',
	props:{
		text:{
			type:String,
			default:''
		},
	},
	setup(props:any) {
		const typedText = ref(''); // 组件内部定义响应式数据
		const speed = 100; // 每个字符打印的间隔时间
		// 在组件挂载后执行的函数，使用setInterval实现打字机效果
		const startTyping = ()=> {
			let i = 0;
			const typingInterval = setInterval(() => {
				if (i < props.text.length) {
					typedText.value += props.text.charAt(i);
					i++;
				} else {
					clearInterval(typingInterval);
				}
			}, speed);
		};
		startTyping();
		return {
			typedText,
		};
	},
	mounted() {
		 // 调用函数开始打字机效果
	},
};
