
import { defineComponent,ref } from 'vue'
import { Tabs } from 'ant-design-vue';
import PromptTag from './promptTag.vue'
import Tag from './Tag.vue';
import Draggable from 'vuedraggable'
import { getCatePrompt,getPrompt } from '@/api/prompt/prompt'
import { PromptType,PromptArray } from './typeInterface'
export default defineComponent({
  components: { 
    Tabs,
    TabPane:Tabs.TabPane,
    PromptTag,
    Draggable,
    Tag
   },
	name:'Prompt',
  props:{
    nature:{
      type:String,
      default:'right'
    },
  },
  setup(props){
    const activeKey = ref<number>(1)
    const typeList = ref<Array<any>>([])
    const promptData = ref<Array<PromptType>>([])
    const dragging = ref<Boolean>(false)
    const getPromptType = ()=>{
      getCatePrompt().then((res:any)=>{
        typeList.value = res
        getPromptById(res[0].id)
      })
    }
    // 根据类型，查询prompt词
    const getPromptById = (id:string|number)=>{
      let data:any = {
        id:id
      }
      if(props.nature=='right'){
        data.is_forward = 1
      }else{
        data.is_negative = 1
      }
      
      getPrompt(data).then((res:any)=>{
        promptData.value = res
        console.log(promptData.value)
      })
    }
    // 切换Tabs
    const change = (key:any)=>{
      getPromptById(key)
    }
    const cloneDog = (e:any)=>{
      // let data = e
      // data.disabled = false
      return e
    }
    getPromptType()
    return{
			typeList,
      activeKey,
      promptData,
      dragging,
      change,
      cloneDog
		}
	}
})
