import { PromptType,PromptArray } from '@/components/prompt/typeInterface'
import router from '@/router/index'
import LocalStorage from './localStorage';
import { translate } from '@/api/prompt/translate'
// 计算权重
export const calculateWeight = (str:string) => {
  // 计算prompt权重
  if (str.match(/^\(\([\w\W]*\)\)$/)) {
    // 判断是输入的是括号法
    const matches = str.match(/\(|\)/g);
    const numPairs = matches ? matches.length / 2 : 0;
    const weight = Math.pow(1.1, numPairs).toFixed(2);
    return weight;
  } else if (str.match(/^\(\(\w+:\d+(\.\d+)?\)\)$/)) {
    // 判断输入的字符串是否符合忽略的格式
    return '';
  } else {
    // 数字法
    const match = str.match(/\((.*?)\)/);
    if (match) {
      const numStr = match[1].match(/:\s*(\d+(\.\d+)?)/)?.[1];
      if (numStr) {
        return parseFloat(numStr).toFixed(2);
      }
    }
  }
};
// 让prompt进行排序
// export const sortedData = (prompt:Array<PromptType>): PromptType[] => {
//   const promptSort = prompt.sort((a:any, b:any) => {
//     if (a.typeIndex === b.typeIndex) {
//       const aIndex:any = a.promptData.length > 0 ? a.promptData[0].index : Number.MAX_SAFE_INTEGER;
//       const bIndex:any = b.promptData.length > 0 ? b.promptData[0].index : Number.MAX_SAFE_INTEGER;
//       return aIndex - bIndex;
//     } else {
//       return a.typeIndex - b.typeIndex;
//     }
//   }).map(item => {
//     item.promptData = item.promptData.sort((a, b) => a.index - b.index);
//     return item;
//   });
//   return promptSort;
// };
// 把json的prompt格式化成可直接复制使用的字符串
export const formatPromptData = (data:Array<PromptType>)=> {
  try {
    const formattedTexts = data.flatMap(({ promptData }) => promptData)
    .map(({ prompt, disabled }) => disabled ? prompt.trim() : '')
    .filter(prompt => prompt !== '');
    return formattedTexts.join(',');
  } catch (error) {
    return ''
  }
  
}
// 格式化输入框的prompt,并且翻译
export const promptArrayFormat = (str:String)=>{
  return new Promise((resolve, reject) => {
    const formatRes = str.split(',').map((item, index) => {
      const prompt = item.trim();
      // 翻译还是有问题,暂时不上线翻译功能
      // console.log(prompt)
      // // 提取英文字符串，进行翻译
      // const data = {
      //   form:prompt
      // }
      // console.log(data)
      // translate(data).then(res=>{
      //   console.log(res)
      // })
      // const fanyi = debounce(()=>{
      //   const data = {
      //     form:prompt
      //   }
      //   console.log(data)
      //   translate(data).then(res=>{
      //     console.log(res)
      //   })
      // })
      // fanyi()
      // console.log(prompt,'这里翻译？？？')
      return {
        text: '',
        prompt: `${prompt}`,
        disabled: '1',
      };
    });
    resolve(formatRes)
  })
  
}
// 防抖函数
let timeoutId: any = null;
export const debounce = (func: any, delay = 1000) :any => {
  return function(...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(()=>args);
    }, delay);
  };
}
// 节流函数
export const throttle = (func: any, delay = 1000) :any => {
  let timeoutId: any = null;
  return function(...args: any[]) {
    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        func.apply(()=>args);
        timeoutId = null;
      }, delay);
    }
  };
}
// 跳转页面
export const toUrl = (name:string,query={})=>{
  router.push({
    name:name,
    params:query
  })
}
// 跳转页面
export const getUrl = (name:string,data={})=>{
  router.push({
    name:name,
    query:data
  })
}
// 获取文件地址前缀
export const fileDomain = ()=>{
  const config = LocalStorage.getItem('config')
  return config.domain
}
// 上传图片，不需要保留前缀
export const replaceFile = (fileUrl:any)=>{
  try {
    return fileUrl.replace(fileDomain(), '');
  } catch (error) {
    return ''
  }
}
// 截取多余的字符串
export const subString = (str:String,maxLength:number = 100)=>{
  let res =  str.substring(0, maxLength);
  res += ' ...'
  return res
}
// 翻译时提取英文
export const filterTags = (input: string)=>{
  // const result = input.replace(/[(){},:]/g, ' ').replace(/[_-]/g, ' ');
  // 过滤掉所有非英文字母和非 -_ 的字符
  const filtered = input.replace(/[^\w-]/g, ' ');

  // 将 -_ 替换为空格
  const replaced = filtered.replace(/[_-]/g, ' ');

  // 去掉数字
  const noNumbers = replaced.replace(/\d+/g, '');

  // 去掉多余空格
  const trimmed = noNumbers.replace(/\s+/g, ' ').trim();

  return trimmed;

  // return result;
}