import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {
    Button,
    Layout,
    Row,
    Col,
    Image,
} from 'ant-design-vue'

createApp(App)
.use(router)
.use(Button)
.use(Layout)
.use(Row)
.use(Col)
.use(Image)
.mount('#app')
