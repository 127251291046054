import Login from '@/components/login/login.vue'
import { createApp,watch } from 'vue'
import LocalStorage from '@/utils/localStorage';
let LogInCmp: any = null

export function useLogin(onHide?: () => void | undefined) {
  // 如果之前没有挂载过loading组件，就先挂载到全局
  if (!LogInCmp) {
    const app = createApp(Login)
    LogInCmp = app.mount(document.createElement('div'))
    document.body.appendChild(LogInCmp.$el)
  }
  watch(()=>LogInCmp.visible,(v)=>{
    if (!v) {
      LogInCmp.visible = false
      if(onHide){
        onHide()
      }
    }
  })
  // 返回的方法
  return {
    show: (modal:Boolean=true) => {
      LogInCmp.visible = true
      LogInCmp.modal = modal
    },
    hide: () => {
      LogInCmp.visible = false
    },
    logOut: ()=>{
      LocalStorage.clearItem('userInfo')
    }
  }
}