
import { defineComponent,ref,watch } from 'vue'
import { Upload } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { uploadImage } from '@/api/utils/utils'
export default defineComponent({
  components: { 
    Upload,
    PlusOutlined
  },
	name:'Uplpad',
  props:{
    maxCount:{
      type:Number,
      default:1
    },
    value:{
      type: Array,
      default:[]
    }
  },
  emits:{

  },
  setup(props, { emit }){
    const maxCount = props.maxCount
    const fileList = ref<any>([])
    fileList.value = props.value
    watch(()=>props.value,(v)=>{
      fileList.value = v
    })
    // 自定义上传行为
    const uploadImg = (data:any)=>{
      const uid = data.file.uid
      uploadImage(data).then((res:any)=>{
        fileList.value = fileList.value.filter((file:any) => file.uid !== uid )
        fileList.value.push(
          {
            uid:res.id,
            uri:res.url,
            url:res.uri,
            name:res.name
          }
        )
        emit('update:value', fileList.value);
      }).catch(()=>{
        const file = fileList.value.find((file: any) => file.uid === uid);
        if (file) {
          file.status = 'error'; // 修改 status 属性值为 'done'
        }
      })
    }
    return{
			fileList,
      maxCount,
      uploadImg,
		}
	}
})
