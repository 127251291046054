import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05284ab2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "prompt-item-text prompt-item-prompt" }
const _hoisted_2 = { class: "prompt-item-text prompt-item-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Space = _resolveComponent("Space")!

  return (_openBlock(), _createBlock(_component_Space, { size: 10 }, {
    default: _withCtx(() => [
      _createVNode(_component_Dropdown, { trigger: ['contextmenu'] }, {
        overlay: _withCtx(() => [
          _createVNode(_component_Menu, null, {
            default: _withCtx(() => [
              ($setup.Tag.disabled==1)
                ? (_openBlock(), _createBlock(_component_MenuItem, {
                    key: 0,
                    onClick: $setup.ondisabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("禁用")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              ($setup.Tag.disabled=='')
                ? (_openBlock(), _createBlock(_component_MenuItem, {
                    key: 1,
                    onClick: $setup.onEnable
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("启用")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_MenuItem, { onClick: $setup.onTmt }, {
                default: _withCtx(() => [
                  _createTextVNode("翻译")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_MenuItem, { onClick: $setup.onDelete }, {
                default: _withCtx(() => [
                  _createTextVNode("删除咒语")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_MenuItem, { onClick: $setup.editP }, {
                default: _withCtx(() => [
                  _createTextVNode("编辑咒语")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_MenuItem, { onClick: $setup.editT }, {
                default: _withCtx(() => [
                  _createTextVNode("编辑中文")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", {
            class: _normalizeClass(["prompt-item list-group-item", {'disabled':$setup.Tag.disabled==''}])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _withDirectives(_createElementVNode("span", null, _toDisplayString($setup.Tag.prompt), 513), [
                [_vShow, $setup.promptEdit]
              ]),
              _withDirectives(_createVNode(_component_Input, {
                value: $setup.Tag.prompt,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.Tag.prompt) = $event)),
                bordered: false,
                size: "small",
                onPressEnter: $setup.handleBlur,
                onBlur: $setup.handleBlur
              }, null, 8, ["value", "onPressEnter", "onBlur"]), [
                [_vShow, !$setup.promptEdit]
              ])
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_2, [
              _withDirectives(_createElementVNode("span", null, _toDisplayString($setup.Tag.text), 513), [
                [_vShow, $setup.textEdit]
              ]),
              _withDirectives(_createVNode(_component_Input, {
                value: $setup.Tag.text,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.Tag.text) = $event)),
                bordered: false,
                size: "small",
                onPressEnter: $setup.textBlur,
                onBlur: $setup.textBlur
              }, null, 8, ["value", "onPressEnter", "onBlur"]), [
                [_vShow, !$setup.textEdit]
              ])
            ], 512), [
              [_vShow, $setup.Tag.text!=''||!$setup.textEdit]
            ]),
            _withDirectives(_createElementVNode("div", { class: "prompt-item-text prompt-item-weight" }, _toDisplayString($setup.strWeight($setup.Tag.prompt)), 513), [
              [_vShow, $setup.strWeight($setup.Tag.prompt)]
            ])
          ], 2), [
            [_vShow, $setup.Tag.prompt]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}